@import "../../node_modules/bootstrap/scss/bootstrap";

p {
  color: #000;
  font-size: 1rem;
  a {
    color: var(--ion-color-primary) !important;
  }
}

.accordion-body {
  color: #000;
}

ion-loading {
  --background: #f0f9fe !important;
  ion-backdrop {
    background-color: #f0f9fe;
  }
}

ion-card.content-card {
  margin-left: 0px;
  margin-top: 10px;
  margin-right: 0px;
}
ion-modal.profile-modal {
  --width: 100%;
  --min-height: 80vh;
  --max-width: 900px !important;
  ion-item {
    --border-radius: 11px;
    --background: rgb(247, 247, 247);
  }
}

.businessForm {
  ion-item {
    --border-radius: 11px;
    --background: rgb(247, 247, 247);
  }
}

ion-modal.auto-height {
  @media screen and (min-width: 767px) {
    --height: auto;
  }
  --max-width: 900px;

  .modal-wrapper {
    .ion-page {
      justify-content: unset;
      @media screen and (min-width: 767px) {
        max-height: 90vh;
        position: relative;
        contain: content;
      }
      .modal-content-custom {
        overflow: auto;
        padding: 20px;
      }
    }
  }
}

.blue-bg-gradient {
  background-image: linear-gradient(180deg, #075c8f 0%, #0f4c6b 100%);
}
.ada-statement {
  h2 {
    margin-bottom: 15px;
  }
  ion-card.content-card {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  margin-top: 90px;
  background-image: linear-gradient(180deg, #075c8f 0%, #0f4c6b 100%);
  // background-image: linear-gradient(180deg, #f1f1f1 0%, #fffc 100%);
}

.accordion-button {
  font-size: 18px;
}

.icon-box {
  padding: 0 17px !important;
  h2 {
    font-size: 30px !important;
  }
}
/* Highlight Benefits */
.hb-list {
  h2 {
    text-align: center !important;
    margin-bottom: 15px;
  }
  padding: 0px 0px;
  ion-label {
    font-size: 15px !important;
    font-weight: 500;
    h3 {
      color: rgb(59, 59, 59);
    }
  }
  .resource-list {
    padding: 0px 0px;
  }
  p {
    font-size: 18px;
    text-align: justify;
  }
  .hb-label {
    white-space: normal !important;
  }
}

h2 {
  color: #0a0a0a;
  font-size: 28px;
  font-weight: 400;
}

hr {
  color: #9e9e9e;
}

h2.home {
  font-size: 30px;
  font-weight: 400;
}

h3.home {
  text-align: center;
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 35px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 2px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.nav-card {
  &.tile {
    padding: 15px !important;
  }
  padding: 10px !important;
  box-shadow: 0px 9px 28px 0px rgba(21, 32, 45, 0.15);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 15px;
  background-color: #ffffff;
  text-align: center;
  h4 {
    margin-bottom: 10px;
  }
  &.start {
    margin-right: 10px;
  }
  &.center {
    margin-left: 10px;
    margin-right: 10px;
  }
  &.end {
    margin-left: 10px;
  }
  .inner {
    align-content: space-evenly;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .icon {
    font-size: 50px;
  }
  h4 {
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -1.6px;
  }
}
.nav-card:hover {
  box-shadow: 0px 9px 28px 0px rgba(21, 32, 45, 0.25);
}
