/* Project Detail Card */
.react-share__ShareButton {
  width: 100%;
}
ion-popover.alert-list-popover {
  --width: 300px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.alert-list-popover .popover-content {
  padding: 0px 10px;
  border-radius: 15px;
}
ion-popover.social-share-popover {
  --width: 210px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.social-share-popover .popover-content {
  padding: 0px 10px;
}
ion-popover.project-list-popover {
  --width: 270px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.project-list-popover .popover-content {
  padding: 1px 10px;
  border-radius: 18px;
}
ion-popover.more-menu-popover {
  --width: 200px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.more-menu-popover .popover-content {
  padding: 1px 10px;
  border-radius: 18px;
}
ion-card.project-detail ion-card-title {
  font-size: 28px !important;
}
ion-card ion-card-content {
  padding-bottom: 10px !important;
}

.project-location-date-wrap-indesign {
  color: #000;
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #08619e0f;
  border: 1px solid #08629e13;
}
.project-location-date-wrap-inconstruction {
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #fff7e4;
  border: 1px solid #f5eedb;
}
.project-location-date-wrap-completed {
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #5ed37325;
  border: 1px solid #50b86323;
}
.project-location {
  margin-bottom: 0px;
}

.project-location-date-wrap-indesign .project-start-date-title,
.project-location-date-wrap-indesign .project-end-date-title,
.project-location-date-wrap-indesign .project-location-title {
  color: var(--ion-color-indesign-shade);
  font-weight: 600;
}
.project-location-date-wrap-inconstruction .project-start-date-title,
.project-location-date-wrap-inconstruction .project-end-date-title,
.project-location-date-wrap-inconstruction .project-location-title {
  color: var(--ion-color-inconstruction-shade);
  font-weight: 600;
}
.project-location-date-wrap-completed .project-start-date-title,
.project-location-date-wrap-completed .project-end-date-title,
.project-location-date-wrap-completed .project-location-title {
  color: var(--ion-color-completed-shade);
  font-weight: 600;
}

.project-body-text {
  margin-top: 15px;
}
.project-body-text {
  margin: 0px 0px 16px;
}
.project-text p {
  margin-bottom: 15px;
}
.project-body-text h2 {
  color: #000 !important;
  font-size: 18px;
  line-height: 1.3;
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
.project-body-text h2.form-title {
  color: #000 !important;
  font-size: 20px;
  line-height: 1.3;
  margin-top: -20px !important;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}

.about-body-text h2 {
  color: #000 !important;
  font-size: 22px;
  line-height: 1.3;
  margin-top: 20px;
  margin-bottom: 6px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}

.item .sc-ion-label-ios-h {
  white-space: unset;
}
.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  margin: 10px 0px !important;
}

.block {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  // padding-left: env(safe-area-inset-left);
  font-size: var(--f7-block-font-size);
}

.project-images::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.project-images > img {
  max-height: 175px;
  width: auto;
  border-radius: 16px;
  scroll-snap-align: start start;
  margin-right: 10px;
  background-color: #f1f1f1;
}

@media (min-width: 768px) {
  .project-images img {
    margin-right: 15px;
  }
}

.project-images {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  white-space: nowrap;
  font-size: 0;
  scroll-padding-left: calc(16px + env(safe-area-inset-left));
}

.project-images:after {
  content: "";
  width: 1px;
  height: 1px;
  flex-shrink: 0;
}
