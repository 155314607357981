#Projects {
  .fixed-content {
    // background-color: #f0f0f0 !important;
  }
  ion-grid {
    padding: 0px 8px;
  }
  ion-card {
    margin: 16px 8px;
  }
  @media (max-width: 768px) {
    ion-card.no-margin-mobile {
      margin-bottom: 0px !important;
    }
  }
  .network-error {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.7) !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1;
  }
}
