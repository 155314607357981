#Alerts {
  .alert-list {
    background: #ffffff00;
    border-radius: 15px !important;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    .alert-list-item {
      font-weight: 500;
      --inner-padding-top: 0px;
      --inner-padding-bottom: 0px;
      --padding-top: 6px !important;
      --padding-bottom: 6px !important;
      //   margin-bottom: 10px;
      --background: #fff;
      --inner-padding-start: 0px;
      --inner-padding-end: 20px;
      --border-radius: 15px !important;
      --border-color: #ececec;
      --border-width: 1px !important;

      //   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12) !important;
      ion-label {
        h3 {
          font-size: 25px !important;
          font-weight: 600;
          margin: 5px 0px 10px 0px;
        }
        font-weight: 500;
        p {
          margin-top: 5px;
          font-size: 16px !important;
          color: rgb(53, 53, 53);
          font-weight: 400;
        }
      }
    }
  }
}
