.wave-divider {
  // overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  // bottom: -1px;
  margin-bottom: -45px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 45px;
    transform: translateX(-50%) rotateY(180deg);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    .shape-divider-fill {
      fill: #f0f9fe;
      // fill: #fff;
    }
    margin-bottom: -45px;
  }
}
.shape-divider {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  // bottom: -1px;
  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 94px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    .shape-divider-fill {
      fill: #075c8f;
    }
  }
}
