main {
  background-color: var(--ion-color-dark);
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#google_translate_element {
  z-index: 1 !important;
  position: absolute !important;
  bottom: 0 !important;
  width: 100%;
  text-align: right;
}
.goog-te-gadget-simple {
  border-left: 1px solid #d5d5d5 !important;
  border-top: 1px solid #d5d5d5 !important;
  border-bottom: 1px solid #ffffff !important;
  border-right: 1px solid #ffffff !important;
  border-top-left-radius: 8px !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}

.goog-te-menu-frame {
  max-width: 100% !important;
}
.goog-te-menu2 {
  max-width: 100% !important;
  overflow-x: scroll !important;
  box-sizing: border-box !important;
  height: auto !important;
}
.sticky-content {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.webmap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.esri-ui {
  border: 5px solid #fff;
  border-radius: 20px;
}

.esri-ui .esri-popup--is-docked {
  display: none !important;
}

.disclaimer {
  font-size: 14px;
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.678);
}
.alert-wrapper {
  width: 100% !important;
}
.alert {
  border-radius: 15px !important;
}

ion-grid.content-grid {
  --ion-grid-width: 1600px !important;
  /* max-width: 1400px; */
  padding-top: 30px;
}
ion-grid.ada-statement {
  --ion-grid-width: 1600px !important;
  /* max-width: 1400px; */
  padding-top: 50px;
  padding-bottom: 30px;
}

div.toolbar-container {
  max-width: 1150px !important;
  margin: 0 auto !important;
}

ion-item {
  --highlight-height: 0px;
}
ion-item.item-disabled {
  opacity: 0.6;
}

ion-loading {
  --backdrop-opacity: 1 !important;
  --background-color: var(--ion-color-primary);
}

#menuLeft {
  --width: 280px;
}

/* #menuLeft::slotted(backdrop) {
  display: none;
} */

ion-menu ion-list#menu-list {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
}

ion-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
ion-menu ion-list {
  padding: 20px 25px;
}
ion-menu ion-note {
  margin-bottom: 30px;
}
ion-menu ion-list-header,
ion-menu ion-note {
  padding-left: 10px;
}
#menuLeft ion-item {
  --padding-start: 20px;
  --padding-end: 0px;
  border-radius: 20px;
  --background: none;
  --color: var(--ion-color-primary);
  --background-hover: rgba(var(--ion-color-primary-rgb), 1);
  --background-hover-opacity: 0.15;
  --detail-icon-color: #fff;
  --detail-icon-opacity: 1;
  margin-bottom: 5px;
}

#menuLeft ion-item.selected {
  --background: var(--ion-color-primary) !important;
  --color: #fff;
}
ion-menu ion-item ion-icon {
  color: var(--ion-color-primary);
  font-size: 32px;
}
ion-menu ion-item.selected ion-icon {
  color: #fff;
}
ion-menu ion-item ion-label {
  font-weight: 600;
  font-size: 22px !important;
}

/** Header **/

ion-header {
  background: var(--ion-color-primary);
  /* background: var(--ion-color-primary);
  max-width: 1400px !important;
  margin: 0 auto !important; */
  box-shadow: 0px -6px 17px 0px rgb(0 0 0 / 58%);
}

ion-toolbar {
  /* max-width: 1300px !important; */
  margin: 0 auto !important;
  --background: var(--ion-color-primary);
  --min-height: 70px;
  --border-width: 0px !important;
  box-shadow: 0px -6px 17px 0px rgb(0 0 0 / 0%);
}
ion-toolbar h3 {
  font-size: 1.4rem;
}
.logo-wrap {
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.svg-wrap {
  width: 80px;
  max-width: 80px;
}
.svg-wrap-home {
  width: 90%;
}
.logo-path {
  fill: #fff;
}
.cot-logo-wrap {
  width: 230px;
  max-width: 230px;
  /* height: 65px;
  width: auto; */
  /* padding: 10px 15px; */
  padding-left: 0;
  margin-left: 10px;
}
.logo-title {
  width: 140px;
  max-width: 181px;
  margin-left: 10px;
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0.9em;
  letter-spacing: 0.7px;
}
.cot-logo-wrap-footer {
  height: 65px;
  width: auto;
  padding: 10px 15px;
  padding-left: 0;
}
.cot-logo {
  height: 100%;
  width: auto;
  /* display: block;
  margin: auto 0; */
}
.horizontal-menu-button {
  margin: 0px 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.button-active {
  /* border-bottom: 2px solid var(--ion-color-primary-tint); */
  --color: var(--ion-color-secondary) !important;
  border-bottom: 2px solid #fff;
}

/* Custom Footer */
.fixed-content {
  /* min-height: calc(100% - 78px); */
  min-height: calc(100% - 84px);
}
@media (max-width: 768px) {
  .fixed-content {
    min-height: calc(100% - 160px);
  }

  .footer-center {
    margin-bottom: 10px;
  }
}
@media (max-width: 772px) {
  .projects-row {
    flex-direction: column-reverse;
  }
}
#accordionExample {
  /* max-width: 800px; */
  margin: 0px;
}
.accordion-item {
  background-color: #fff !important;
  text-align: left;
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--ion-color-primary) !important;
  background-color: #e7f1ff !important;
  box-shadow: inset 0 -1px 0 rgba(169, 169, 169, 0.13) !important;
  border-radius: 10px !important;
}

.custom-footer {
  height: auto;
  /* background: #f0f9fe; */
  background: #fff;
  box-shadow: 0px 0px 10px rgba(2, 8, 20, 0.06);
  padding: 20px 0px 10px;
  color: var(--ion-color-dark);
  font-size: 13px;
}

.fixed-content {
  /* background-color: #fff; */
  background-color: #f0f9fe;
  background-size: cover;
  background-position: center center;
}
/** Page Background **/
.page-content::part(background) {
  background-color: #fff;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%238eced4'/%3E%3Cstop offset='1' stop-color='%2380bac0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2393d6dc' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2393d6dc' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%2393d6dc' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2393d6dc' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E"); */
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
}
ion-card {
  border-radius: 20px;
}
ion-card.card-margin {
  margin: 10px 8px;
}
ion-card-content.card-content-login {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}
.content-card {
  border-radius: 18px;
  margin-bottom: 0px;
}
.user-card {
  border-radius: 18px;
  margin-top: 0px;
}

ion-modal {
  --width: 800px;
}
ion-modal.profile-modal {
  --width: 1000%;
}
.modal-content-custom p {
  margin-bottom: 0px;
}
.modal-content-custom h4 {
  font-size: 20px;
  margin-top: -10px;
}
.modal-header {
  background-color: rgb(241, 241, 241);
  color: #000;
  padding: 0.7rem 1rem !important;
}
.modal-title {
  font-size: 27px !important;
  font-weight: 700 !important;
}
.modal-header .close {
  position: relative;
  top: 5px;
  right: 5px;
  color: var(--ion-color-primary) !important;
  opacity: 0.9;
}
.modal-component {
  max-height: 80vh;
}
#alert-button {
  --inner-padding-start: 0px;
}
@media only screen and (max-width: 990px) {
  .project-list-responsive {
    display: none;
  }
}
ion-list.project-list ion-label {
  font-size: 14px !important;
  font-weight: 500;
}
ion-list.project-list ion-label.ion-text-wrap {
  overflow: clip !important;
}
ion-list.project-list a,
ion-list.alert-list a {
  text-decoration: none;
}
ion-list.project-list {
  padding: 0px 15px !important;
  margin-top: 10px;
}
ion-list.project-list ion-item {
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  margin-bottom: 10px;
  --background: #f8f8f8;
  --inner-padding-start: 10px;
  --inner-padding-end: 5px;
  --border-radius: 8px !important;
  --border-color: #ededed;
  --border-width: 1px !important;
  --detail-icon-color: #797979;
  --background-activated-opacity: 0;
  --background-hover-opacity: 0.03;
}

ion-list.project-list ion-item {
  --padding-start: 10px;
}

ion-list.project-list a,
ion-list.alert-list a {
  text-decoration: none;
}
ion-list.project-list {
  padding: 0px !important;
  margin-top: 10px;
}
ion-list.project-list.document-list {
  margin-top: 20px !important;
}
ion-list.project-list ion-item {
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  margin-bottom: 10px;
  --background: #f8f8f8;
  --inner-padding-start: 10px;
  --inner-padding-end: 5px;
  --border-radius: 8px !important;
  --border-color: #ededed;
  --border-width: 1px !important;
  --detail-icon-color: #666666;
  --background-hover-opacity: 0.03;
  /* --background-focused: #fff !important; */
  --background-focused-opacity: 0.08;
}

ion-list.project-list ion-item {
  --padding-start: 12px;
}

ion-list.project-list ion-item.selected-inDesign {
  --detail-icon-color: var(--ion-color-indesign-tint);
  --detail-icon-opacity: 1;
  --color: #000;
  --background: #08619e0f;
  --border-color: #08629e13;
}
ion-list.project-list ion-item.selected-inConstruction {
  --detail-icon-color: var(--ion-color-inconstruction);
  --detail-icon-opacity: 1;
  --color: #000;
  --background: #fff7e4;
  --border-color: #f5eedb;
}
ion-list.project-list ion-item.selected-completed {
  --detail-icon-color: var(--ion-color-completed-tint);
  --detail-icon-opacity: 1;
  --color: #000;
  --border-color: #50b86323;
  --background: #5ed37325;
}

ion-list.project-list ion-item {
  font-size: 16px;
  font-weight: 700;
}

ion-popover.project-list-popover {
  --width: 270px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.project-list-popover .popover-content {
  padding: 1px 10px;
  border-radius: 18px;
}

ion-modal ion-toolbar {
  --min-height: 60px !important;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-completed {
  --ion-color-base: var(--ion-color-completed);
  --ion-color-base-rgb: var(--ion-color-completed-rgb);
  --ion-color-contrast: var(--ion-color-completed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-completed-contrast-rgb);
  --ion-color-shade: var(--ion-color-completed-shade);
  --ion-color-tint: var(--ion-color-completed-tint);
}
.ion-color-indesign {
  --ion-color-base: var(--ion-color-indesign);
  --ion-color-base-rgb: var(--ion-color-indesign-rgb);
  --ion-color-contrast: var(--ion-color-indesign-contrast);
  --ion-color-contrast-rgb: var(--ion-color-indesign-contrast-rgb);
  --ion-color-shade: var(--ion-color-indesign-shade);
  --ion-color-tint: var(--ion-color-indesign-tint);
}
.ion-color-inconstruction {
  --ion-color-base: var(--ion-color-inconstruction);
  --ion-color-base-rgb: var(--ion-color-inconstruction-rgb);
  --ion-color-contrast: var(--ion-color-inconstruction-contrast);
  --ion-color-contrast-rgb: var(--ion-color-inconstruction-contrast-rgb);
  --ion-color-shade: var(--ion-color-inconstruction-shade);
  --ion-color-tint: var(--ion-color-inconstruction-tint);
}

/** Ionic CSS Variables **/
:root {
  /* --color: var(
    --ion-card-color,
    var(--ion-item-color, var(--ion-color-step-600, #666666))
  ); */
  --ion-backdrop-opacity: 0.7;
  --ion-grid-width-xl: 1550px;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Roboto", sans-serif;

  --ion-color-primary: #08619e;
  --ion-color-primary-rgb: 8, 97, 158;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #07558b;
  --ion-color-primary-tint: #2171a8;

  --ion-color-secondary: #99d644;
  --ion-color-secondary-rgb: 153, 214, 68;
  --ion-color-secondary-contrast: #141d07;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #87bc3c;
  --ion-color-secondary-tint: #a3da57;

  --ion-color-tertiary: #ffd717;
  --ion-color-tertiary-rgb: 255, 215, 23;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0bd14;
  --ion-color-tertiary-tint: #ffdb2e;

  --ion-color-success: #7dca64;
  --ion-color-success-rgb: 125, 202, 100;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #6eb258;
  --ion-color-success-tint: #8acf74;

  --ion-color-warning: #f5b100;
  --ion-color-warning-rgb: 245, 177, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d89c00;
  --ion-color-warning-tint: #f6b91a;

  --ion-color-danger: #c93547;
  --ion-color-danger-rgb: 201, 53, 71;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b12f3e;
  --ion-color-danger-tint: #ce4959;

  --ion-color-indesign: #0072bc;
  --ion-color-indesign-rgb: 0, 114, 188;
  --ion-color-indesign-contrast: #ffffff;
  --ion-color-indesign-contrast-rgb: 255, 255, 255;
  --ion-color-indesign-shade: #0064a5;
  --ion-color-indesign-tint: #1a80c3;

  --ion-color-inconstruction: #f58a00;
  --ion-color-inconstruction-rgb: 245, 138, 0;
  --ion-color-inconstruction-contrast: #000000;
  --ion-color-inconstruction-contrast-rgb: 0, 0, 0;
  --ion-color-inconstruction-shade: #d87900;
  --ion-color-inconstruction-tint: #f6961a;

  --ion-color-completed: #259e3c;
  --ion-color-completed-rgb: 37, 158, 60;
  --ion-color-completed-contrast: #ffffff;
  --ion-color-completed-contrast-rgb: 255, 255, 255;
  --ion-color-completed-shade: #218b35;
  --ion-color-completed-tint: #3ba850;

  --ion-color-dark: #2e2e2e;
  --ion-color-dark-rgb: 46, 46, 46;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #282828;
  --ion-color-dark-tint: #434343;

  --ion-color-medium: #9d9d9f;
  --ion-color-medium-rgb: 157, 157, 159;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #8a8a8c;
  --ion-color-medium-tint: #a7a7a9;

  --ion-color-light: #d6d5d7;
  --ion-color-light-rgb: 214, 213, 215;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #bcbbbd;
  --ion-color-light-tint: #dad9db;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 255, 255, 255;
  --ion-color-black-contrast: #000000;
  --ion-color-black-contrast-rgb: 0, 0, 0;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #000000;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  /* 
  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  } */

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
}
