#Workforce {
  p.blue-bg {
    // font-size: 1.1rem !important;
    text-shadow: 1px 1px 2px #000000;
  }
  h2.blue-bg {
    color: #fff !important;
    font-size: 25px !important;
    font-weight: 500 !important;
    line-height: 1.4em;
    letter-spacing: 1px;
    margin-top: 0px;
    text-shadow: 1px 1px 2px #000000;
  }
  h3.blue-bg {
    text-shadow: 1px 1px 2px #000000;
    font-size: 25px !important;
  }
  a {
    text-decoration: none;
  }
  .dotted-divider {
    display: flex;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 0px;
    span {
      direction: ltr;
      display: flex;
      width: 90%;
      margin: 0 auto;
      border-top: 1px dashed #c9c9c9;
    }
  }
  .job-list {
    max-width: 235px;
    margin: 0 auto;
  }
  .ada-statement ion-card.content-card {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .account-item {
    --transition: none;
    --border-radius: 10px !important;
    --border-style: solid;
    --border-width: 1px;
    --border-color: #ededed;
    --background: #f6f6f6 !important;
    margin: 8px 4px;
  }
  .list-item {
    --transition: none;
    --border-radius: 10px !important;
    --border-style: solid;
    --border-width: 1px;
    --border-color: #ededed;
    --background: #f6f6f6 !important;
    margin: 15px 4px;
    span.detailText {
      color: #9b9b9b;
    }
  }
  .img-right {
    max-height: 190px;
    width: auto;
    float: right;
    margin: 5px 5px 0px 30px;
  }
}
