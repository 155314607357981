section.title-header {
  background-position: center center;
  background-size: cover;
  height: auto;

  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #083c5bc9 0%, #073f5fe0 100%);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    display: flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    h1 {
      color: #ffffff;
      font-family: "Montserrat", Sans-serif;
      font-size: 45px;
      font-weight: 600;
      line-height: 1.2em;
      text-shadow: 2px 2px 2px #000000;
      margin: 50px auto 100px;
      max-width: 900px;
      padding: 0px 20px;
      word-wrap: normal;
    }
    p.blue-bg {
      text-shadow: 1px 1px 2px #000000;
    }
  }
}
section.login-header {
  background-position: center center;
  background-size: cover;
  height: auto;

  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #083c5bc9 0%, #073f5fe0 100%);
    // background-image: linear-gradient(360deg, #083c5bed 0%, #064a72f0 100%);
    // background-image: linear-gradient(210deg, #0a5f7ee0 0%, #032d3edb 100%);
    // background-image: linear-gradient(210deg, #0a5f7ee0 0%, #024a65db 100%);
    // background-image: linear-gradient(210deg, #7aaf40de 0%, #005878f2);
    // background-image: linear-gradient(210deg, #4c6d28de 0%, #005878f2);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    display: flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    h1 {
      color: #000;
      font-family: "Montserrat", Sans-serif;
      font-size: 40px;
      font-weight: 600;
      line-height: 1.2em;
      // text-shadow: 2px 2px 2px #000000;
      margin: 10px auto 100px;
      // max-width: 1000px;
      max-width: 800px;
    }
  }
}
