#lightboxModal {
  --width: 100vw !important;
  --height: 100vh !important;
  .lightbox-header {
    display: block !important ;
    background: var(--ion-color-primary) !important;
    ion-toolbar {
      --min-height: 56px;
      --border-width: 0px;
      --min-height: 56px;
      /* --background: #0d0d0d !important; */
      --opacity: 0.9;
      /* color: #fff; */
    }
  }
  .swiper-container {
    background: rgb(21, 21, 21) none repeat scroll 0% 0% !important;
  }

  .swiper-pagination-bullet {
    background: #fff;
  }
  .swiper-zoom-container {
    text-align: center;
    max-height: 90%;
    img {
      width: auto;
      height: 90%;
    }
  }
  .image-caption {
    height: 10%;
    text-align: center;
    p {
      color: #fff !important;
    }
  }
}

// ion-modal ion-header {
//   display: block !important ;
//   background: var(--ion-color-primary) !important;
// }

// ion-modal {
//   --width: 100vw !important;
//   --height: 100vh !important;
// }

// .swiper-container {
//   background: rgb(21, 21, 21) none repeat scroll 0% 0% !important;
// }

// .swiper-pagination-bullet {
//   background: #fff;
// }

// .swiper-zoom-container img {
//   width: auto;
//   height: 90%;
// }
// .swiper-zoom-container {
//   text-align: center;
//   max-height: 90%;
// }

// .image-caption {
//   height: 10%;
//   text-align: center;
//   p {
//     color: #fff !important;
//   }

// }
