h5.job-list {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  display: inline-block;
  background: #99d644;
  padding: 6px 15px 25px 15px;
  border-radius: 10px;
  margin-bottom: -19px;
  z-index: 1;
  position: relative;
}

p.job-list {
  margin-bottom: 16px !important;
  background: #f2f1f1;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
