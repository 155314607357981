.loadingWrap {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: auto;
  bottom: 0px;
  top: 0px;
  left: 0;
  position: absolute;
  .loadingContent {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* margin is -0.5 * dimension */
    margin-top: -25px;
  }
}
